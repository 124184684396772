import { Link, Typography } from '@mui/material'
import { navSecondaryLinkSx } from './nav-menu-item-style'

type NavSecondaryLinkProps = {
  label: string
  url: string
}

export function NavSecondaryLink({ label, url }: NavSecondaryLinkProps): JSX.Element {
  return (
    <Link sx={navSecondaryLinkSx} href={url} target="_blank" rel="noopener noreferrer">
      <Typography variant="subtitle2">{label}</Typography>
    </Link>
  )
}
