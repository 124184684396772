import { css } from '@emotion/react'
import { HorizontalLogo, primaryColor, spacing, useScreenType } from '@orus.eu/pharaoh'
import type { Permission } from '@orus.eu/right-access-management'
import { type ReactNode } from 'react'
import { useSession } from '../../lib/session'
import { CustomerMenu } from '../organisms/customer-menu'
import { InviteToLoginPage } from './invite-to-login/InviteToLoginPage'
import { MenuPage } from './menu-page'
import { OrusAgentBlocker } from './no-orus-agents'

export default function CustomerPage({
  children,
}: {
  children: ReactNode
  requiredPermissions: Permission[]
}): ReactNode {
  const largeScreen = useScreenType() === 'desktop'

  const {
    user,
    permissions: { type },
  } = useSession()

  if (!user) return <InviteToLoginPage />
  if (type === 'platform' && 'email' in user) return <OrusAgentBlocker email={user.email} />
  if (type !== 'client') return <InviteToLoginPage />

  return (
    <MenuPage
      menuTitle={
        <div
          css={css`
            translate: '0px 20px';
            padding: ${spacing[60]} ${spacing[60]} 0 ${spacing[60]};
          `}
        >
          <HorizontalLogo height={largeScreen ? 21 : 17} color={primaryColor} />
        </div>
      }
      menuBody={<CustomerMenu />}
      pageContent={children}
    />
  )
}
