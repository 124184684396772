import { css } from '@emotion/react'
import { Icon, Text, borderRadius, controlBorder, shadow, spacing, useTranslate } from '@orus.eu/pharaoh'
import { useCallback } from 'react'
import { openChat } from '../../lib/hubspot-util'
import { cguLink, confidentialityLink } from '../../lib/static-links'
import { UnstyledButton } from '../atoms/unstyled-button'
import { DisconnectButtonLink } from '../molecules/disconnect-button-link'
import { NavMenuItem } from '../molecules/nav-menu-item'
import { navSecondaryLinkSx } from '../molecules/nav-menu-item-style'
import { NavSecondaryLink } from '../molecules/nav-secondary-link'

import { trpcReact } from '../../client'
import anneHelp from '../../images/anne-help.png'
import { useEmbeddingPartnerConfiguration } from '../../lib/embedding-partner'
import { getMutaSituation } from '../../lib/muta-util'
import { GlobalLoadingState } from '../molecules/global-loading-state'

export function CustomerMenu(): JSX.Element {
  const translate = useTranslate()
  const embeddingPartnerConfiguration = useEmbeddingPartnerConfiguration()
  const [contracts] = trpcReact.contracts.listMyContracts.useSuspenseQuery()

  const embeddingPartnerReturnUrl = embeddingPartnerConfiguration?.returnUrl

  const returnToEmbeddingPartner = useCallback(() => {
    if (embeddingPartnerReturnUrl) {
      document.location.href = embeddingPartnerReturnUrl
    }
  }, [embeddingPartnerReturnUrl])

  if (!contracts) {
    return <GlobalLoadingState />
  }

  const { isMuta, isMutaOnly } = getMutaSituation(contracts)
  const operatingZone = contracts[0]?.operatingZone ?? 'fr'

  return (
    <nav
      css={css`
        margin-top: ${spacing[70]};
        padding: ${spacing[60]};
        display: 'flex';
        flex-direction: 'column';
        height: '100%';
        gap: ${spacing[60]};
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${spacing[20]};
          margin-top: ${spacing[70]};
        `}
      >
        <NavMenuItem to="/" icon="house-solid" label={translate('home')} variant="clientapp" />
        <NavMenuItem
          to="/contracts"
          icon="folder-solid"
          label={translate('my_contracts')}
          variant="clientapp"
          data-testid="nav-my-contract"
        />
        {isMuta ? (
          <NavMenuItem
            to="/health-insurance"
            icon="heart-solid"
            label={translate('my_health_insurance')}
            variant="clientapp"
            data-testid="nav-my-health-insurance"
          />
        ) : (
          <></>
        )}
        <NavMenuItem
          to="/contract-guarantees"
          icon="umbrella-simple-solid"
          label={translate('my_guarantees')}
          variant="clientapp"
          data-testid="nav-my-guarantees"
        />
        <NavMenuItem to="/invoices" icon="receipt-solid" label={translate('my_invoices')} variant="clientapp" />
        <NavMenuItem
          to="/payment-methods/update"
          icon="credit-card-solid"
          label={translate('my_payment_method')}
          variant="clientapp"
        />
        {isMutaOnly ? (
          <></>
        ) : (
          <NavMenuItem to="/report-claim" icon="bolt-solid" label={translate('claims')} variant="clientapp" />
        )}
        <NavMenuItem to="/account" icon="circle-user-solid" label={translate('my_account')} variant="clientapp" />
        {embeddingPartnerConfiguration?.returnUrl ? (
          <NavMenuItem
            onClick={returnToEmbeddingPartner}
            icon="arrow-left-regular"
            label={translate('customer_app_return_to_partner', { partnerName: embeddingPartnerConfiguration.name })}
            variant="clientapp"
          />
        ) : (
          <></>
        )}
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-top: auto;
          gap: ${spacing[50]};
        `}
      >
        <HelpSection />
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: ${spacing[20]};
          `}
        >
          <NavSecondaryLink label={translate('confidentiality')} url={confidentialityLink[operatingZone]} />
          <NavSecondaryLink label={translate('general_terms')} url={cguLink[operatingZone]} />
          <DisconnectButtonLink sx={navSecondaryLinkSx}>{translate('disconnect')}</DisconnectButtonLink>
        </div>
      </div>
    </nav>
  )
}

function HelpSection(): JSX.Element {
  const translate = useTranslate()
  const handleHelp = useCallback(openChat, [openChat])

  return (
    <>
      <UnstyledButton
        sx={{
          border: controlBorder,
          padding: spacing[50],
          borderRadius: borderRadius[40],
          boxShadow: shadow.bottom[10],
          marginTop: spacing[50],
          display: 'flex',
          gap: spacing[50],
          alignItems: 'center',
          justifyContent: 'start',
        }}
        fullWidth
        onClick={handleHelp}
      >
        <img src={anneHelp} alt="help" width="48px" height="48px" />

        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: start;
          `}
        >
          <Text variant="body1Semibold">{translate('question_cta_title')}</Text>
          <Text
            css={css`
              max-width: 152px;
              text-align: left;
              text-wrap: wrap;
            `}
            variant="body2"
          >
            {translate('question_cta_subtitle')}
          </Text>
        </div>
        <div
          css={css`
            margin-left: auto;
          `}
        >
          <Icon icon="arrow-right-regular" size="40" />
        </div>
      </UnstyledButton>
    </>
  )
}

//152
